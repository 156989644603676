import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="remote-modal"
export default class extends Controller {
  connect() {
    this.element.showModal();

    this.element.addEventListener("click", (e) => {
      if (e.target === this.element) {
        e.stopPropagation();
      }
    });

    let button = this.element.querySelector(".modal-btn");
    if (button) {
      button.addEventListener("click", () => this.close());
    }
  }

  close() {
    this.element.close();
  }
}
